import React from "react";
import { Script } from "gatsby"

const GoogleReview = () => {
    return (
        <div className="google-review-wrapper d-md-flex align-items-center">
            <Script src="https://apps.elfsight.com/p/platform.js" strategy="idle" />
            {/* <div className="elfsight-app-c51ba559-d3b7-4195-93d2-1461a390244f" data-elfsight-app-lazy></div> */}
        </div>
    )

}

export default GoogleReview